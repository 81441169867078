import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { CommonValidators } from '@core/validators';
import { MatChipInputComponent } from '@shared/components';
@Component({
  selector: 'app-cp-chips',
  standalone: true,
  imports: [
    MatChipInputComponent,
    JsonPipe,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
      <section>
        <h4>Chips</h4> 
        <form [formGroup]="form" class="chips-input">
          <app-mat-chip-input formControlName="bin" label="Card prefix number" [required]="false"/>
          <app-mat-chip-input formControlName="binsRequired" label="Card prefix number Required" [required]="true"/>
          <button mat-button color="primary" [disabled]="form.invalid">Submit</button>
        </form>
        <button mat-button color="primary" (click)="reset()">Reset</button>

        <p>Form value: {{ form.value | json }}</p>
      </section>
  `,
  styles: `
    .chips-input {
        width: 100%;
    }
`
})
export class CpChipsComponent {
  form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      bin: [[], [CommonValidators.cardPrefixNumber()]],
      binsRequired: [[], [CommonValidators.cardPrefixNumber()]]
    });
  };
  reset(){
    this.form.reset({
      bin:[],
      binsRequired:[]
    })
  }
}
