import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-cp-search',
  standalone: true,
  imports: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <p>
      cp-search works!
    </p>
  `,
  styles: ``
})
export class CpSearchComponent {

}
