import { KeyValuePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { KYCStatusEnum, VerificationRequestStatusEnum } from '@core/enums';
import { StatusComponent } from '@shared/components';
import { KycStatusPipe, VerificationRequestStatusPipe } from '@shared/pipes';

@Component({
  selector: 'app-cp-status',
  standalone: true,
  imports: [MatTabsModule, StatusComponent, KeyValuePipe, KycStatusPipe, VerificationRequestStatusPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    @for (typography of typographyClasses; track $index) {
      <h2>{{ typography }}</h2>
      @for (status of kycStatusValues | keyvalue; track $index) {
        @for (showIcon of [true, false]; track $index) {
          @for (showBorder of [true, false]; track $index) {
            <app-status
              [data]="$any(status.value) | kycStatus"
              [showIcon]="showIcon"
              [showBorder]="showBorder"
              [typography]="typography">
            </app-status>
          }
        }
      }
    }
    @for (typography of typographyClasses; track $index) {
      <h2>{{ typography }}</h2>
      @for (status of verificationRequestValues | keyvalue; track $index) {
        @for (showIcon of [true, false]; track $index) {
          @for (showBorder of [true, false]; track $index) {
            <app-status
              [data]="$any(status.value) | verificationRequestStatus"
              [showIcon]="showIcon"
              [showBorder]="showBorder"
              [typography]="typography">
            </app-status>
          }
        }
      }
    }
  `,
  styles: ``
})
export class CpStatusComponent {
  kycStatusValues = Object.values(KYCStatusEnum).filter(value => typeof value === 'number') as number[];
  verificationRequestValues = Object.values(VerificationRequestStatusEnum).filter(value => typeof value === 'number') as number[];
  typographyClasses: string[] = ['Caption', 'Body-1'];
}
