import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-cp-empty-state',
  standalone: true,
  imports: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <p>
      cp-empty-state works!
    </p>
  `,
  styles: ``
})
export class CpEmptyStateComponent {

}
