import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Icons } from '@core/enums';
interface FontawesomeIcon {
  type: 'fal' | 'fas' | 'far' | 'fab';
  name: string;
}
@Component({
  selector: 'app-cp-icons',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="icons">
      @for (pack of fontAwesomeIcons | keyvalue; track pack.key) {
        <div class="pack-group">
          <h2>{{pack.key}}</h2>
          @for (size of leterialSizes; track $index) {
            <p class="icon-row {{size}}">
              @for (icon of pack.value; track $index) {
                <span class="{{icon.type}} {{icon.name}}"></span>
              }
            </p>
          }
        </div>
      }
    </div>
    <div *ngFor="let icon of Icons | keyvalue" class="icon-set">
      <h3>{{ icon.value }}</h3>
      <div class="size-row">
        <div *ngFor="let size of sizes" class="size-item">
          <mat-icon [svgIcon]="icon.value" [class]="'mat-' + size"></mat-icon>
          <span>{{ size }}px</span>
        </div>
      </div>
    </div>
  `,
  styles: `
    span{
      flex-basis:32px;
      text-align:center;
    }
    .icons{ 
      .icon-row{
        display:flex;
        justify-content:space-evenly;
      } 
    }
    .large{
      font-size: 22px;
      font-weight: 300;
      line-height: 22px;
    }
    .medium{ 
      font-size: 18px;
      font-weight: 300;
      line-height: 18px;
    }
    .small{ 
      font-size: 16px;
      font-weight: 300;
      line-height: 16px;
    }
    .tiny{
      font-size: 14px;
      font-weight: 300;
      line-height: 14px;
    }
    .icon-set {
      margin-bottom: 20px;
    }
    .size-row {
      display: flex;
      align-items: center;
      gap: 20px;
    }
    .size-item {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  `
})
export class CpIconsComponent {
  Icons = Icons

  sizes = [12, 16, 20, 24, 32];
  fontAwesomeIcons: Record<string, FontawesomeIcon[]> =
    {
      'Icon Pack 1': [
        { type: 'fal', name: 'fa-arrow-down' },
        { type: 'fal', name: 'fa-arrow-up' },
        { type: 'fal', name: 'fa-arrow-left' },
        { type: 'fal', name: 'fa-arrow-right' },
        { type: 'fal', name: 'fa-angle-left' },
        { type: 'fal', name: 'fa-angle-right' },
        { type: 'fal', name: 'fa-angle-up' },
        { type: 'fal', name: 'fa-angle-down' },
        { type: 'fas', name: 'fa-caret-up' },
        { type: 'fas', name: 'fa-caret-down' },
        { type: 'fal', name: 'fa-circle-arrow-up' },
        { type: 'fal', name: 'fa-circle-arrow-down' },
        { type: 'fal', name: 'fa-xmark' },
        { type: 'fal', name: 'fa-check' },
        { type: 'fal', name: 'fa-stopwatch' },
        { type: 'fal', name: 'fa-ticket-simple' },
        { type: 'fal', name: 'fa-user-headset' },
      ],
      'Icon Pack 2': [
        { type: 'fal', name: 'fa-trash' },
        { type: 'fal', name: 'fa-wallet' },
        { type: 'fal', name: 'fa-gear' },
        { type: 'fal', name: 'fa-arrows-rotate' },
        { type: 'fal', name: 'fa-eye' },
        { type: 'fal', name: 'fa-eye-slash' },
        { type: 'fal', name: 'fa-circle-user' },
        { type: 'fal', name: 'fa-calendar' },
        { type: 'fal', name: 'fa-envelope' },
        { type: 'fal', name: 'fa-clock-rotate-left' },
        { type: 'fal', name: 'fa-hourglass-empty' },
        { type: 'fal', name: 'fa-user-group' },
        { type: 'fal', name: 'fa-bell' },
        { type: 'fal', name: 'fa-filter' },
        { type: 'fal', name: 'fa-message-lines' },
        { type: 'fal', name: 'fa-gift' },
        { type: 'fal', name: 'fa-shield-exclamation' },
      ],
      'Icon Pack 3': [
        { type: 'fal', name: 'fa-circle-info' },
        { type: 'fal', name: 'fa-grid-2' },
        { type: 'fal', name: 'fa-gem' },
        { type: 'fal', name: 'fa-building-columns' },
        { type: 'fal', name: 'fa-life-ring' },
        { type: 'fal', name: 'fa-user' },
        { type: 'fal', name: 'fa-circle-minus' },
        { type: 'fal', name: 'fa-circle-plus' },
        { type: 'fal', name: 'fa-copy' },
        { type: 'fal', name: 'fa-spinner-third fa-spin' },
        { type: 'fal', name: 'fa-clock' },
        { type: 'fal', name: 'fa-hand-back-point-up' },
        { type: 'fal', name: 'fa-folder-open' },
        { type: 'fal', name: 'fa-pen-to-square' },
        { type: 'far', name: 'fa-circle-play' },
      ],
      'Icon Pack 4': [
        { type: 'fal', name: 'fa-download' },
        { type: 'fal', name: 'fa-file' },
        { type: 'fal', name: 'fa-shield-check' },
        { type: 'fal', name: 'fa-paperclip' },
        { type: 'fal', name: 'fa-paper-plane-top' },
        { type: 'fal', name: 'fa-flag-swallowtail' },
        { type: 'fal', name: 'fa-triangle-exclamation' },
        { type: 'fal', name: 'fa-circle-exclamation' },
        { type: 'fal', name: 'fa-circle-check' },
        { type: 'fal', name: 'fa-credit-card' },
        { type: 'fab', name: 'fa-android' },
        { type: 'fal', name: 'fa-circle-question' },
        { type: 'fal', name: 'fa-plus' },
        { type: 'fal', name: 'fa-thumbs-up' },
        { type: 'fal', name: 'fa-thumbs-down' },
        { type: 'fal', name: 'fa-file-export' }
      ],
      "Icon Pack 5": [
        { type: 'far', name: 'fa-ellipsis-vertical' },
        { type: 'far', name: 'fa-bars' },
        { type: 'fal', name: 'fa-globe' },
        { type: 'far', name: 'fa-house' },
        { type: 'far', name: 'fa-arrow-down-arrow-up' },
        { type: 'far', name: 'fa-arrow-right-from-bracket' },
        { type: 'fas', name: 'fa-xmark' }
      ]
    };
  leterialSizes = ['large', 'medium', 'small', 'tiny']
}