import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-cp-spacing',
  standalone: true,
  imports: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
  <div class="container">
    <div class="box px-xxxs" title="px-xxxs"></div>
    <div class="box px-xxs" title="px-xxs"></div>
    <div class="box px-xs" title="px-xs"></div>
    <div class="box px-s" title="px-s"></div>
    <div class="box px-m" title="px-m"></div>
    <div class="box px-l" title="px-l"></div>
    <div class="box px-xl" title="px-xl"></div>
    <div class="box px-xxl" title="px-xxl"></div>
    <div class="box px-xxxl" title="px-xxxl"></div>
    <div class="box px-auto" title="px-auto"></div>
  </div>
  `,
  styles: `
    .container {
    display: flex;
    flex-direction:row-reverse;
    flex-wrap: wrap;
    gap: 10px;
    height:500px;
  }

  .box {
    width:auto;
    background:#D9D9D9;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }`
})
export class CpSpacingComponent {

}
