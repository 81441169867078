/* eslint-disable @typescript-eslint/no-empty-function */
import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { CommonValidators } from '@core/validators';
import { FileUploaderComponent } from '@shared/components';

@Component({
  selector: 'app-cp-upload-file',
  standalone: true,
  imports: [
    FileUploaderComponent,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    JsonPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <app-file-uploader formControlName="file1" required autoUpload></app-file-uploader>
        <app-file-uploader formControlName="file2" [acceptedFormats]="['.jpg','.png','.svg','.ico']"></app-file-uploader>
        <app-file-uploader formControlName="file3" [maxFileSize]="30000"></app-file-uploader>
        <app-file-uploader formControlName="file4" dragDropMessage="drop any file you want in drop zone" chooseFileMessage="Select File"></app-file-uploader>
        <app-file-uploader formControlName="file5" [maxFileSize]="5000" [minFileSize]="2000"></app-file-uploader>
        <button mat-flat-button color="primary" [disabled]="form.invalid">Submit</button>
    </form>  
    
    <p>{{form.value | json}}</p>
    `,
  styles: ``
})
export class CpUploadFileComponent {
  form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      file1: [null, CommonValidators.required],
      file2: [null],
      file3: [null],
      file4: [null],
      file5: [null],
    });
  }

  onSubmit() {}
}
