import { Component } from '@angular/core';

@Component({
  selector: 'app-cp-alert',
  standalone: true,
  imports: [],
  template: `
    <div class="app-alert app-alert--warning mb-xxxl">
        <span class="app-alert__icon fa-regular fa-circle-info"></span>
        The allowable amount of speared is 0% to 10%.
    </div>
  `,
  styles: ``
})
export class CpAlertComponent {

}
