import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-cp-selection-controls',
  standalone: true,
  imports: [MatCheckboxModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
        <mat-checkbox color="primary">Default</mat-checkbox>
        <mat-checkbox color="primary" [checked]="true">Checked</mat-checkbox>
        <mat-checkbox color="primary" [disabled]="true">Disabled</mat-checkbox>
        <mat-checkbox color="primary" [checked]="true" [disabled]="true">Checked but disabled</mat-checkbox>
  `,
  styles: `
  :root{
  }
  `
})
export class CpSelectionControlsComponent {

}
